import React from 'react'
import { SignedIn, SignedOut, UserButton, SignUpButton, useUser } from '@clerk/clerk-react'
import { Link } from 'react-router-dom'
import logo from '../../assets/small1.png'
import logo2 from '../../assets/logo2.png'

function Navbar() {
    return (
        <div className='fixed top-0 left-0 right-0 z-20 border-b border-slate-200 bg-white'>
            <div className='flex items-center justify-between max-w-7xl mx-auto'>
                <div>
                    <Link to='/' className='w-full flex items-end justify-start py-4'>
                        <img src={logo} alt="err" className='w-12' />
                        <img src={logo2} alt="err" className='w-72' />
                    </Link>
                </div>

                <div className='flex items-center justify-center space-x-4'>

                    <div className='flex items-center justify-center space-x-8 text-sm font-medium secFont list-none'>
                        <li>
                            <Link to='/about'>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link to='/pricing'>
                                Pricing
                            </Link>
                        </li>
                        <li>
                            <Link to='/contact'>
                                Contact Us
                            </Link>
                        </li>
                    </div>

                    <div className='border-l border-slate-200 pl-4'>
                        <SignedOut>
                            <SignUpButton mode="modal" >
                                <button className='bg-gray-900 text-white px-4 py-2 secFont text-sm font-semibold rounded'>
                                    Get Started Now
                                </button>
                            </SignUpButton>
                        </SignedOut>


                        <div className='flex items-center justify-center'>
                            <SignedIn>
                                <Link to='/dashboard' className='mr-4 text-gray-900 bg-white border px-4 py-2 secFont text-sm font-semibold rounded'>
                                    Dashboard
                                </Link>
                                <UserButton />
                            </SignedIn>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Navbar
