import React, { useEffect } from 'react';
import { SignedIn, useUser } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/data';

function ClientProtectedRoute(props) {
    const { authURL } = useAuth();
    const { Component } = props;
    const { isSignedIn, user, isLoaded } = useUser();

    useEffect(() => {
        if (isSignedIn && user) {
            sendClientData();
        }
    }, []);

    if (!isLoaded) {
        return (
            <div className="bg-white max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
                <l-zoomies
                    size="80"
                    stroke="5"
                    bg-opacity="0.1"
                    speed="1.4"
                    color="black"
                ></l-zoomies>
            </div>
        );
    }

    if (isSignedIn) {
        <Navigate to="/dashboard" />;
    } else {
        return <Navigate to="/login" />;
    }

    async function sendClientData() {
        const clientData = {
            fullName: user.fullName,
            email: user.primaryEmailAddress.emailAddress,
            username: user.username
        };

        try {
            const response = await fetch(`${authURL}/post-user-stats`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(clientData),
            });

            if (response.status === 412) {
                return console.error('Already Exists');
            } else if (response.status === 201) {
                console.log('OK');
            } else {
                return console.log("Internal Server Error");
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <SignedIn>
                <Component />
            </SignedIn>
        </>
    );
}

export default ClientProtectedRoute;
