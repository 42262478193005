import React from 'react';
import TaxPayerForm from './Form';

const TaxPayer = () => {
    return (
        <>
            <TaxPayerForm></TaxPayerForm>
        </>
    );
};

export default TaxPayer;