import React from 'react';
import HeroSection from './HeroSection';
import Features from './Features';
import Stats from './Stats';
import Services from './Services';

const Homepage = () => {
    return (
        <>
            <HeroSection></HeroSection>
            <Stats></Stats>
            <Features></Features>
            <Services></Services>
        </>
    );
};

export default Homepage;
