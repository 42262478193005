import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../context/data';
import { useUser } from '@clerk/clerk-react';

const InvoiceHashComponent = () => {
    const { user } = useUser();
    const { authURL, userEmail } = useAuth();
    const [base64, setBase64] = useState('');
    const [output, setOutput] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [invoiceHashingCount, setInvoiceHashingCount] = useState(0);
    const [isTrialEnded, setIsTrialEnded] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const fetchUserStatistics = async () => {
        try {
            const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`);
            if (!response.ok) {
                throw new Error('Failed to fetch user statistics');
            }

            const userData = await response.json();
            const invoiceHashing = userData.count.invoiceHashing;
            setInvoiceHashingCount(invoiceHashing);
            setLoaded(true);
            if (invoiceHashing >=7) {
                setIsTrialEnded(true);
            } else {
                setIsTrialEnded(false);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    const handleSignInvoice = async () => {
        if (isTrialEnded) {
            return; // Do not proceed if the trial has ended
        }

        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${authURL}/invoice-sign-simulation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userEmail}`, // Assuming authorization uses email
                },
                body: JSON.stringify({ base64 }),
            });

            if (!response.ok) {
                throw new Error('Failed to sign the invoice');
            }

            const data = await response.json();
            setOutput(data);

            // Increment invoiceHashing count after a successful sign
            await updateUserInvoiceHashingCount();
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const updateUserInvoiceHashingCount = async () => {
        try {
            const response = await fetch(`${authURL}/update-user-stats?email=${user.primaryEmailAddress.emailAddress}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'count.invoiceHashing': invoiceHashingCount + 1  // Only update count.invoiceHashing field
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update invoice hashing count');
            }

            setInvoiceHashingCount(invoiceHashingCount + 1);
        } catch (err) {
            setError(err.message);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    useEffect(() => {
        fetchUserStatistics();
    }, [fetchUserStatistics]);

    if (isTrialEnded) {
        return <p className="text-red-500 mt-4">Your trial has ended. Please upgrade your plan to continue.</p>;
    }

    return (
        <>
            {!isLoaded && !isTrialEnded ? (
                <div className="max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
                    <l-zoomies
                        size="80"
                        stroke="5"
                        bg-opacity="0.1"
                        speed="1.4"
                        color="black"
                    ></l-zoomies>
                </div>
            ) : (
                <div className="w-full mx-auto">

                    <div className='flex items-end justify-between mb-12'>
                        <div>
                            <h1 className='text-5xl font-light mb-2'>Invoice Signing & Hash</h1>
                        </div>

                        <div className='flex flex-col items-end justify-end space-x-2'>
                            <h5 class="text-4xl font-bold text-blue-700 bg-blue-300 px-4 py-2 rounded-xl">{invoiceHashingCount}</h5>
                            <span class="text-md text-gray-500">Trials Remaining</span>
                        </div>

                    </div>

                    <h2 className="text-2xl font-medium mb-4">Try it Now</h2>
                    <textarea
                        className="w-full p-3 bg-gray-100 border border-gray-300 outline-none rounded mb-4"
                        rows="5"
                        placeholder="Enter base64 encoded XML"
                        value={base64}
                        onChange={(e) => setBase64(e.target.value)}
                    />
                    <button
                        className="bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-lg transition duration-200 px-4 py-2 rounded disabled:opacity-50"
                        onClick={handleSignInvoice}
                        disabled={loading}
                    >
                        {loading ? 'Signing...' : 'Sign Invoice'}
                    </button>

                    {error && <p className="text-red-500 mt-4">{error}</p>}

                    {output && (
                        <div className="mt-6 space-y-8">
                            <div>
                                <code className="text-lg font-medium">Invoice Hash:</code>
                                <div className="flex items-center mt-2 space-x-4">
                                    <pre className="bg-gray-100 p-4 flex flex-col space-y-2 rounded">
                                        <code className="text-sm">[INFO] InvoiceSigningService - invoice has been signed successfully</code>
                                        <code className="text-sm">[INFO] InvoiceSigningService - *** INVOICE HASH = <span className="text-emerald-700">{output.hash}</span></code>
                                    </pre>
                                    <button onClick={() => copyToClipboard(output.hash)} className="bg-gray-100 p-4 rounded"><i className="fa-regular fa-copy"></i></button>
                                </div>
                            </div>

                            <div>
                                <code className="text-lg font-medium">Base64 Invoice:</code>
                                <div className="flex items-center mt-2 space-x-4">
                                    <div className="overflow-auto max-w-full">
                                        <pre className="bg-gray-100 p-4 flex flex-col space-y-2 rounded">
                                            <code className="text-sm">[INFO] InvoiceSigningService - invoice has been signed successfully</code>
                                            <code className="text-sm">[INFO] InvoiceSigningService - *** BASE64 INVOICE:</code>
                                            <code className="text-sm text-emerald-700 overflow-x-auto whitespace-pre-wrap pb-4">{output.base64}</code>
                                        </pre>
                                    </div>

                                    <button onClick={() => copyToClipboard(output.base64)} className="bg-gray-100 p-4 rounded"><i className="fa-regular fa-copy"></i></button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default InvoiceHashComponent;
