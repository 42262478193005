import React from 'react';
import ComplianceForm from './Form';

const Compliance = () => {
    return (
        <>
            <ComplianceForm></ComplianceForm>
        </>
    );
};

export default Compliance;