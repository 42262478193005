import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/data';
import { useUser } from '@clerk/clerk-react';

function Dashboard() {
    const { user } = useUser();
    const { authURL } = useAuth();

    // Individual states for each count item
    const [invoiceHashing, setInvoiceHashing] = useState(0);
    const [invoiceQR, setInvoiceQR] = useState(0);
    const [taxpayer, setTaxpayer] = useState(0);
    const [onboardDevice, setOnboardDevice] = useState(0);
    const [compliance, setCompliance] = useState(0);
    const [productionPCSID, setProductionPCSID] = useState(0);
    const [clearance, setClearance] = useState(0);
    const [loading, setLoading] = useState(true);
    const [userPackage, setUserPackage] = useState('');

    const fetchUserStatistics = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`);
            if (!response.ok) {
                throw new Error('Failed to fetch user statistics');
            }
            const userData = await response.json();
            // Set the individual states based on the fetched data
            setInvoiceHashing(userData.count.invoiceHashing);
            setInvoiceQR(userData.count.invoiceQR);
            setTaxpayer(userData.count.taxpayer);
            setOnboardDevice(userData.count.onboardDevice);
            setCompliance(userData.count.compliance);
            setProductionPCSID(userData.count.productionPCSID);
            setClearance(userData.count.clearance);
            setUserPackage(userData.package);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const getFeatureLimit = (pkg) => {
        switch (pkg) {
            case 'basic':
                return 6;
            case 'gold':
                return 100;
            case 'platinum':
                return 1000;
            default:
                return 0;
        }
    };

    useEffect(() => {
        fetchUserStatistics();
    }, []);

    return (
        <div className='w-full max-w-5xl mx-auto space-y-12 h-[99vh]'>

            <div className='flex space-x-2 items-end justify-between'>
                <div className='flex flex-col space-y-2 items-start'>
                    <h1 className='text-6xl font-bold'>Dashboard</h1>
                    <p className='mb-8 font-regular'>See your ZATCA APIs usage, API Keys and much more under one roof!</p>
                </div>

                <div className='flex flex-col space-y-2 items-end justify-end'>
                    <div className='bg-green-600 py-2 px-8 rounded-full'>
                        <h1 className='text-gray-200 capitalize'>{userPackage}</h1>
                    </div>
                    <div>
                        <p className='text-gray-500'>
                            Total Features Allowed: {getFeatureLimit(userPackage)}
                        </p>
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
                    <l-zoomies
                        size="80"
                        stroke="5"
                        bg-opacity="0.1"
                        speed="1.4"
                        color="black"
                    ></l-zoomies>
                </div>
            ) : (
                <div className='grid grid-cols-3 gap-4'>

                    <div className='flex items-center justify-center flex-col space-y-2 bg-gray-800 h-[10rem] rounded'>
                        <h1 className='text-6xl text-gray-200'>{invoiceHashing}</h1>
                        <h1 className='text-xl text-gray-200'>Invoice Signing</h1>
                    </div>

                    <div className='flex items-center justify-center flex-col space-y-2 bg-gray-800 h-[10rem] rounded'>
                        <h1 className='text-6xl text-gray-200'>{invoiceQR}</h1>
                        <h1 className='text-xl text-gray-200'>Invoice QR Generator</h1>
                    </div>

                    <div className='flex items-center justify-center flex-col space-y-2 bg-gray-800 h-[10rem] rounded'>
                        <h1 className='text-6xl text-gray-200'>{taxpayer}</h1>
                        <h1 className='text-xl text-gray-200'>Taxpayer CSR</h1>
                    </div>

                    <div className='flex items-center justify-center flex-col space-y-2 bg-gray-800 h-[10rem] rounded'>
                        <h1 className='text-6xl text-gray-200'>{onboardDevice}</h1>
                        <h1 className='text-xl text-gray-200'>Onboarding Device</h1>
                    </div>

                    <div className='flex items-center justify-center flex-col space-y-2 bg-gray-800 h-[10rem] rounded'>
                        <h1 className='text-6xl text-gray-200'>{compliance}</h1>
                        <h1 className='text-xl text-gray-200'>Compliance</h1>
                    </div>

                    <div className='flex items-center justify-center flex-col space-y-2 bg-gray-800 h-[10rem] rounded'>
                        <h1 className='text-6xl text-gray-200'>{productionPCSID}</h1>
                        <h1 className='text-xl text-gray-200'>Production PCSID</h1>
                    </div>

                    <div className='flex items-center justify-center flex-col space-y-2 bg-gray-800 h-[10rem] rounded'>
                        <h1 className='text-6xl text-gray-200'>{clearance}</h1>
                        <h1 className='text-xl text-gray-200'>Invoice Clearance</h1>
                    </div>

                    <div className='flex items-center justify-center flex-col space-y-2 bg-gray-800 h-[10rem] rounded'>
                        <h1 className='text-6xl text-gray-200'>{clearance}</h1>
                        <h1 className='text-xl text-gray-200'>Invoice Reporting</h1>
                    </div>

                </div>
            )}
        </div>
    )
}

export default Dashboard;
