import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../context/data';
import { useUser } from '@clerk/clerk-react';

const ClearanceForm = () => {
    const { authURL } = useAuth();
    const [base64, setBase64] = useState('');
    const [binarySecurityToken, setBinarySecurityToken] = useState('');
    const [secret, setSecret] = useState('');
    const [output, setOutput] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [qrCodeData, setQrCodeData] = useState(null);
    const { user } = useUser();
    const [invoiceHashingCount, setInvoiceHashingCount] = useState(0);
    const [isTrialEnded, setIsTrialEnded] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const fetchUserStatistics = async () => {
        try {
            const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`);
            if (!response.ok) {
                throw new Error('Failed to fetch user statistics');
            }

            const userData = await response.json();
            const invoiceHashing = userData.count.clearance;
            setInvoiceHashingCount(invoiceHashing);
            setLoaded(true);
            if (invoiceHashing >= 7) {
                setIsTrialEnded(true);
            } else {
                setIsTrialEnded(false);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    const updateUserInvoiceHashingCount = async () => {
        try {
            const response = await fetch(`${authURL}/update-user-stats?email=${user.primaryEmailAddress.emailAddress}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'count.clearance': invoiceHashingCount + 1  // Only update count.invoiceHashing field
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update invoice hashing count');
            }

            setInvoiceHashingCount(invoiceHashingCount + 1);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleSignInvoice = async () => {
        setLoading(true);
        setError(null);
        setSuccessMessage('');
        setQrCodeData(null);
        try {
            const response = await fetch(`${authURL}/clearance-simulation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    base64Xml: base64,
                    binarySecurityToken,
                    secret
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error);
            }

            const data = await response.json();
            setOutput(data);
            setSuccessMessage('Successful!');
            extractQrCodeData(data.clearedInvoice);
            updateUserInvoiceHashingCount();
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const extractQrCodeData = (clearedInvoice) => {
        try {
            const xmlContent = atob(clearedInvoice); // Decode base64
            console.log('Decoded XML Content:', xmlContent); // Log the decoded XML
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlContent, "application/xml");

            // Check for parsing errors
            const parserError = xmlDoc.getElementsByTagName("parsererror");
            if (parserError.length > 0) {
                console.error('Error parsing XML:', parserError[0].textContent);
                return;
            }

            // Find the AdditionalDocumentReference
            const additionalDocumentReference = xmlDoc.getElementsByTagName("cac:AdditionalDocumentReference")[0];

            if (additionalDocumentReference) {
                // Extract the ID
                const qrCodeId = additionalDocumentReference.getElementsByTagName("cbc:ID")[0]?.textContent || null;
                // Extract the EmbeddedDocumentBinaryObject
                const embeddedDocumentBinaryObject = additionalDocumentReference.getElementsByTagName("cbc:EmbeddedDocumentBinaryObject")[0]?.textContent || null;

                console.log('Extracted Data:', { qrCodeId, embeddedDocumentBinaryObject }); // Log extracted data

                if (qrCodeId || embeddedDocumentBinaryObject) {
                    setQrCodeData({ qrCodeId, embeddedDocumentBinaryObject });
                }
            } else {
                console.error('AdditionalDocumentReference not found');
            }
        } catch (err) {
            console.error('Error parsing XML:', err);
        }
    };

    const downloadXml = () => {
        if (output && output.clearedInvoice) {
            const xmlContent = atob(output.clearedInvoice); // Decode base64
            const blob = new Blob([xmlContent], { type: 'application/xml' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'cleared_invoice.xml'; // Specify the filename
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url); // Clean up
        }
    };

    useEffect(() => {
        fetchUserStatistics();
    }, [fetchUserStatistics]);

    if (isTrialEnded) {
        return <p className="text-red-500 mt-4">Your trial has ended. Please upgrade your plan to continue.</p>;
    }

    return (
        <>
            {!isLoaded && !isTrialEnded ? (
                <div className="max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
                    <l-zoomies
                        size="80"
                        stroke="5"
                        bg-opacity="0.1"
                        speed="1.4"
                        color="black"
                    ></l-zoomies>
                </div>
            ) : (<div className="w-full mx-auto">
                <div className='flex items-end justify-between mb-12'>
                    <div>
                        <h1 className='text-5xl font-light mb-2'>Clearance</h1>
                        <p className='text-md text-gray-600'>Push your Invoices to check the status Clearance of Invoices..</p>
                    </div>

                    <div className='flex flex-col items-end justify-end space-x-2'>
                        <h5 class="text-4xl font-bold text-blue-700 bg-blue-300 px-4 py-2 rounded-xl">{invoiceHashingCount}</h5>
                        <span class="text-md text-gray-500">Trials Remaining</span>
                    </div>

                </div>
                <h2 className="text-2xl font-medium mb-4">Try it Now</h2>
                <textarea
                    className="w-full p-3 bg-gray-100 border border-gray-300 outline-none rounded mb-4"
                    rows="5"
                    placeholder="Enter base64 encoded XML"
                    value={base64}
                    onChange={(e) => setBase64(e.target.value)}
                />
                <input
                    type="text"
                    className="w-full p-3 bg-gray-100 border border-gray-300 outline-none rounded mb-4"
                    placeholder="Binary Security Token"
                    value={binarySecurityToken}
                    onChange={(e) => setBinarySecurityToken(e.target.value)}
                />
                <input
                    type="password"
                    className="w-full p-3 bg-gray-100 border border-gray-300 outline-none rounded mb-4"
                    placeholder="Secret"
                    value={secret}
                    onChange={(e) => setSecret(e.target.value)}
                />
                <button
                    className="bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-lg transition duration-200 px-4 py-2 rounded disabled:opacity-50"
                    onClick={handleSignInvoice}
                    disabled={loading}
                >
                    {loading ? 'Checking...' : 'Check Status'}
                </button>

                {error && <p className="text-red-500 mt-4">{error} - Check that all data is correct!</p>}
                {successMessage && <p className="text-lg text-green-600 mt-4">{successMessage}</p>}

                {output && (
                    <div className="mt-6 space-y-4">
                        <div>
                            <code className="text-lg font-medium">Response from Second API:</code>
                            <pre className="bg-gray-100 p-4 rounded overflow-auto">
                                <code className="text-sm">{JSON.stringify(output, null, 2)}</code>
                            </pre>
                        </div>
                        {output.clearedInvoice && (
                            <div>
                                <code className="text-lg font-medium">Cleared Invoice:</code>
                                <pre className="bg-gray-100 p-4 rounded overflow-auto">
                                    <code className="text-sm">{output.clearedInvoice}</code>
                                </pre>
                                <button
                                    className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
                                    onClick={downloadXml}
                                >
                                    Download Cleared Invoice
                                </button>
                            </div>
                        )}
                        {qrCodeData && (
                            <div className="mt-6">
                                <h3 className="text-lg font-medium">Extracted QR Code Data:</h3>
                                <p><strong>ID:</strong> {qrCodeData.qrCodeId}</p>
                                <p><strong>Embedded Document:</strong> {qrCodeData.embeddedDocumentBinaryObject}</p>
                            </div>
                        )}
                    </div>
                )}
            </div>)}
        </>
    );
};

export default ClearanceForm;
