import React, { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";

const loadRecaptchaScript = () => {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=6LcMU1MqAAAAABzpMgT97VYjrgGGgUINweVTELOf`;
  script.async = true;
  document.body.appendChild(script);
};

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [captchaToken, setCaptchaToken] = useState("");

  useEffect(() => {
    loadRecaptchaScript();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.message
    ) {
      toast.error("Please fill in all the required fields.");
      return;
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute("6LcMU1MqAAAAABzpMgT97VYjrgGGgUINweVTELOf", {
          action: "submit",
        })
        .then((token) => {
          setCaptchaToken(token);
          toast.success("Form submitted successfully!");
          setFormData({ firstName: "", lastName: "", email: "", message: "" });
        })
        .catch(() => {
          toast.error("reCAPTCHA verification failed. Please try again.");
        });
    });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  return (
    <div className="min-h-screen py-32 px-4 sm:px-6 lg:px-8">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="max-w-7xl mx-auto">
        <h1 className="mb-12 text-6xl text-center font-light">
          Get In Touch
        </h1>
        <div className="bg-white rounded border border-slate-200 shadow-2xl overflow-hidden">
          <div className="lg:flex">
            {/* Contact Information */}
            <div className="lg:w-1/3 bg-indigo-600 text-white p-8 lg:p-12">
              <h2 className="text-2xl font-semibold mb-6">Contact Information</h2>
              <div className="space-y-6">
                <div className="flex items-center">
                  <i class="fa-sharp fa-regular fa-location-dot text-white-500 mr-2"></i>
                  <p>United Plaza Qamar Garden Old SheikhuPura Road FSD</p>
                </div>
                <div className="flex items-center">
                  <i class="fa-solid fa-phone-volume text-white-500 mr-2"></i>
                  <p>+1 (555) 123-4567</p>
                </div>
                <div className="flex items-center">
                  <i class="fa-solid fa-envelope text-white-500 mr-2"></i>

                  <p>info@bzsfotech.com</p>
                </div>
                <div className="flex items-center">
                  <i class="fa-solid fa-globe text-white-500 mr-2"></i>
                  <a
                    href="https://www.bzsfotech.com"
                    className="hover:underline"
                  >
                    www.bzsfotech.com
                  </a>
                </div>
              </div>

              {/* Mini Map */}
              <div className="mt-12">
                <h3 className="text-xl font-semibold mb-4">Our Location</h3>
                <div className="rounded-lg overflow-hidden shadow-lg">
                  <iframe
                    title="Our Location"
                    className="w-full h-48"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.710866033661!2d73.1535063155958!3d31.462077151551835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190458d36e8b67%3A0x5dfbfc59d8c37808!2s31.462077251545153%2C%2073.15569541534221!5e0!3m2!1sen!2s!4v1696087597086!5m2!1sen!2s"
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <div className="lg:w-2/3 p-8 lg:p-12">
              <h2 className="text-3xl font-bold text-indigo-900 mb-8">
                Send Us a Message
              </h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 rounded-md placeholder-gray-400 border border-gray-200 focus:border-blue-200 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-300"
                      placeholder="John"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 rounded-md placeholder-gray-400 border border-gray-200 focus:border-blue-200 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-300"
                      placeholder="Doe"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 rounded-md placeholder-gray-400 border border-gray-200 focus:border-blue-200 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-300"
                    placeholder="john@example.com"
                  />
                </div>
                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    rows={4}
                    value={formData.message}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 rounded-md placeholder-gray-400 border border-gray-200 focus:border-blue-200 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-300"
                    placeholder="Your message here..."
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="w-full bg-indigo-600 text-white font-semibold py-3 px-6 rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
