import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './context/data';
import { ClerkProvider, ClerkLoading, ClerkLoaded } from '@clerk/clerk-react';

const clerkPubkey = 'pk_test_YWxsb3dpbmctcGxhdHlwdXMtMTIuY2xlcmsuYWNjb3VudHMuZGV2JA';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <React.StrictMode>
      <BrowserRouter>
        <ClerkProvider publishableKey={clerkPubkey} afterSignInUrl="/dashboard" afterSignUpUrl="/dashboard">
          <ClerkLoading>
            <div className="max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
              <l-zoomies
                size="80"
                stroke="5"
                bg-opacity="0.1"
                speed="1.4"
                color="black"
              ></l-zoomies>
            </div>
          </ClerkLoading>

          <ClerkLoaded>
            <App />
          </ClerkLoaded>

        </ClerkProvider>
      </BrowserRouter>
    </React.StrictMode>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
