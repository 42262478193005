import React from 'react';
import ClearanceForm from './Form';

const Clearance = () => {
    return (
        <>
            <ClearanceForm></ClearanceForm>
        </>
    );
};

export default Clearance;