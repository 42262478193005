import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import { useAuth } from '../../../../context/data'; // Assuming you have similar context
import { useUser } from '@clerk/clerk-react';

const InvoiceQRForm = () => {
    const { user } = useUser();
    const { authURL } = useAuth(); // Use context to get the URL
    const [base64, setBase64] = useState('');
    const [output, setOutput] = useState(null);
    const [qrImage, setQrImage] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [invoiceHashingCount, setInvoiceHashingCount] = useState(0);
    const [isTrialEnded, setIsTrialEnded] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const fetchUserStatistics = async () => {
        try {
            const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`);
            if (!response.ok) {
                throw new Error('Failed to fetch user statistics');
            }

            const userData = await response.json();
            const invoiceHashing = userData.count.invoiceQR;
            setInvoiceHashingCount(invoiceHashing);
            setLoaded(true);
            if (invoiceHashing >= 7) {
                setIsTrialEnded(true);
            } else {
                setIsTrialEnded(false);
            }
        } catch (err) {
            setError(err.message);
        }
    };


    const updateUserInvoiceHashingCount = async () => {
        try {
            const response = await fetch(`${authURL}/update-user-stats?email=${user.primaryEmailAddress.emailAddress}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'count.invoiceQR': invoiceHashingCount + 1  // Only update count.invoiceHashing field
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update invoice hashing count');
            }

            setInvoiceHashingCount(invoiceHashingCount + 1);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleGenerateQR = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${authURL}/invoice-qr-simulation`, { // Use context-based URL
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ base64 }),
            });

            if (!response.ok) {
                throw new Error('Failed to generate QR code');
            }

            const data = await response.json();
            setOutput(data.qrCode); // Store the QR code text

            // Generate QR code image from text
            QRCode.toDataURL(data.qrCode, (err, url) => {
                if (err) {
                    console.error('Error generating QR code image:', err);
                    return;
                }
                setQrImage(url);
            });
            updateUserInvoiceHashingCount()
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };
    
    useEffect(() => {
        fetchUserStatistics();
    }, [fetchUserStatistics]);

    if (isTrialEnded) {
        return <p className="text-red-500 mt-4">Your trial has ended. Please upgrade your plan to continue.</p>;
    }

    return (

        <>
            {!isLoaded && !isTrialEnded ? (
                <div className="max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
                    <l-zoomies
                        size="80"
                        stroke="5"
                        bg-opacity="0.1"
                        speed="1.4"
                        color="black"
                    ></l-zoomies>
                </div>
            ) : (
                <div className="w-full mx-auto">

                    <div className='flex items-end justify-between mb-12'>
                        <div>
                            <h1 className='text-5xl font-light mb-2'>Generating QR Code</h1>
                            <p className='text-md text-gray-600'>Upload a base64 of an XML and get its scanable QR Code</p>
                        </div>

                        <div className='flex flex-col items-end justify-end space-x-2'>
                            <h5 class="text-4xl font-bold text-blue-700 bg-blue-300 px-4 py-2 rounded-xl">{invoiceHashingCount}</h5>
                            <span class="text-md text-gray-500">Trials Remaining</span>
                        </div>

                    </div>

                    <h2 className="text-2xl font-medium mb-4">Try it Now</h2>
                    <textarea
                        className="w-full p-3 bg-gray-100 border border-gray-300 rounded mb-4 outline-none"
                        rows="5"
                        placeholder="Enter base64 encoded XML"
                        value={base64}
                        onChange={(e) => setBase64(e.target.value)}
                    />
                    <button
                        className="bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-lg transition duration-200 px-4 py-2 rounded disabled:opacity-50"
                        onClick={handleGenerateQR}
                        disabled={loading}
                    >
                        {loading ? 'Generating QR...' : 'Generate QR Code'}
                    </button>

                    {error && <p className="text-red-500 mt-4">{error}</p>}

                    {output && (
                        <div className="flex justify-center space-x-4 mt-12">
                            <div className='w-1/2'>
                                <code className="text-lg font-medium">QR Code Data:</code>
                                <div className="flex items-center mt-2 space-x-4">
                                    <pre className="bg-gray-100 p-4 rounded-lg max-w-4xl overflow-x-auto">
                                        <code className="text-sm">{output}</code>
                                    </pre>
                                    <button
                                        onClick={() => copyToClipboard(output)}
                                        className="bg-gray-100 p-2 rounded"
                                    >
                                        <i className="fa-regular fa-copy"></i>
                                    </button>
                                </div>
                            </div>

                            {qrImage && (
                                <div className='w-1/2'>
                                    <code className="text-lg font-medium">QR Code Image:</code>
                                    <img
                                        src={qrImage}
                                        alt="QR Code"
                                        className="mt-2 w-80 rounded border border-gray-300"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>

    );
};

export default InvoiceQRForm;
