import React from 'react'
import bg from '../../assets/bg.png'
import { Link } from 'react-router-dom'

function Features() {
    return (
        <div>
            <section class="max-w-7xl mx-auto py-12 overflow-hidden">
                <div class="container px-4 mx-auto">
                    <h2 class="secFont mb-12 text-6xl tracking-tighter">Exclusive features</h2>
                    <div class="flex flex-wrap -m-3">
                        <div class="w-full md:w-1/2 p-3">
                            <div class="relative p-8 h-full overflow-hidden rounded-lg">
                                <div class="relative z-10 flex flex-col justify-between h-full">
                                    <div class="mb-24">
                                        <h3 class="text-5xl md:text-6xl text-white">APIs based Integration</h3>
                                    </div>
                                    <div class="block">
                                        <p class="mb-6 text-xl text-white tracking-tight">APIs-based integration connects systems for real-time data exchange, improving automation and efficiency.</p>
                                        <Link to='/dashboard/invoice-signing' class="inline-block px-8 py-4 font-semibold bg-white hover:bg-gray-100 tracking-tight rounded focus:ring-4 focus:ring-indigo-300 transition duration-300">Get Started Now</Link>
                                    </div>
                                </div>
                                <img class="absolute top-0 left-0 w-full h-full object-cover" src={bg} alt="" />
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 p-3">
                            <div class="flex flex-col justify-between p-8 h-full bg-black overflow-hidden rounded-lg">
                                <div class="mb-24">
                                    <h3 class="font-heading text-5xl md:text-6xl text-white">Manual Pre-made form Usage.</h3>
                                </div>
                                <div class="block">
                                    <p class="mb-6 text-xl text-white tracking-tight">Manual pre-made forms simplify data entry and ensure consistency.</p>
                                    <Link to='/dashboard/invoice-hashing' class="inline-block px-8 py-4 text-white font-semibold bg-gray-700 hover:bg-gray-800 tracking-tight rounded focus:ring-4 focus:ring-indigo-300 transition duration-300">Get Started Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Features
