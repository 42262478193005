import React, { useEffect, useState } from 'react';
import { SignedIn, useUser } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/data';

function PremiumProtectedRoute({ Component }) {
    const { authURL } = useAuth();
    const { isSignedIn, user, isLoaded } = useUser();
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const getStatus = async () => {
            if (isSignedIn && user) {
                try {
                    const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`);
                    if (response.status === 404) {
                        console.error("Status not found");
                        return;
                    }
                    const data = await response.json();
                    console.log("API Response:", data.package);
                    setStatus(data.package);
                } catch (error) {
                    console.error("Error fetching status:", error);
                }
            }
        };

        getStatus();
    }, [isSignedIn, user, authURL]);

    useEffect(() => {
        console.log("Status updated:", status);
    }, [status]);

    if (!isLoaded) {
        return (
            <div className="max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
                <l-zoomies
                    size="80"
                    stroke="5"
                    bg-opacity="0.1"
                    speed="1.4"
                    color="black"
                ></l-zoomies>
            </div>
        );
    }

    if (!isSignedIn) {
        return <Navigate to="/login" />;
    }

    if (status === null) {
        return (
            <div className=" max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
                <l-zoomies
                    size="80"
                    stroke="5"
                    bg-opacity="0.1"
                    speed="1.4"
                    color="black"
                ></l-zoomies>
            </div>
        );
    }

    if (status === 'basic') {
        return <Navigate to="/" />;
    }

    return (
        <SignedIn>
            <Component />
        </SignedIn>
    );
}

export default PremiumProtectedRoute;
