import { useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../../../context/data';

const OtpPage = () => {
    const { user } = useUser();
    const { authURL } = useAuth();
    const [otp, setOtp] = useState(['', '', '', '', '', '']); // Initialize with six empty strings

    const generateOtp = async () => {
        // Generate a 6-digit OTP
        const generatedOtp = Math.floor(100000 + Math.random() * 900000).toString();
        setOtp(generatedOtp.split('')); // Split the OTP into an array of characters

        // Call the backend API to update the OTP
        try {
            const response = await fetch(`${authURL}/update-user-stats?email=${user.primaryEmailAddress.emailAddress}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    OTP: generatedOtp, // Send the generated OTP
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update OTP');
            }

            const result = await response.json();
            console.log('OTP updated successfully:', result);
        } catch (error) {
            console.error('Error updating OTP:', error);
        }
    };

    return (
        <>
            <div>
                <div>
                    <h1 className='text-5xl font-light mb-6'>Authorization</h1>
                    <p className='text-justify'>Signing and generating an invoice hash involves creating a unique hash of the invoice data and signing it with a private key. This ensures the invoice’s authenticity and integrity by allowing verification that it hasn’t been altered and confirming its origin.</p>
                </div>

                {/* OTP Generation Section */}
                <div className="mt-12 max-w-2xl mx-auto w-full">

                    <div className="w-full h-12 mb-4 secFont flex items-center justify-center text-center bg-white border border-gray-300 rounded-lg text-xl font-light focus:outline-none" >
                        {user.primaryEmailAddress.emailAddress}
                    </div>

                    <div className="mb-4 flex justify-between gap-x-2">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                value={digit}
                                readOnly
                                className="w-24 h-24 secFont text-center border border-gray-300 rounded-lg text-5xl font-bold focus:outline-none"
                            />
                        ))}
                    </div>

                    <button
                        onClick={generateOtp}
                        className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                    >
                        Generate OTP
                    </button>
                </div>
            </div>
        </>
    );
};

export default OtpPage;
