import React from 'react'

function Stats() {
    return (
        <div>
            <section class="py-24 bg-gray-900">
                <div class="max-w-7xl mx-auto px-4">
                    <div class="flex flex-wrap -mx-4">
                        <div class="w-full lg:w-1/3 p-4">
                            <div class="bg-white rounded py-6 px-4">
                                <h2 class="text-gray-900 text-5xl lg:text-6xl font-bold font-heading text-center mb-4">500+</h2>
                                <p class="text-gray-700 font-medium text-center">Collaborated with Trusted Companies</p>
                            </div>
                        </div>
                        <div class="w-full lg:w-1/3 p-4">
                        <div class="bg-white rounded py-6 px-4">
                                <h2 class="text-gray-900 text-5xl lg:text-6xl font-bold font-heading text-center mb-4">10k+</h2>
                                <p class="text-gray-700 font-medium text-center">Happy, Satisfied Customers</p>
                            </div>
                        </div>
                        <div class="w-full lg:w-1/3 p-4">
                        <div class="bg-white rounded py-6 px-4">
                                <h2 class="text-gray-900 text-5xl lg:text-6xl font-bold font-heading text-center mb-4">1M+</h2>
                                <p class="text-gray-700 font-medium text-center">Invoices per month</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Stats
