import React from 'react';
import { Routes, Route } from 'react-router-dom'
import './App.css';

import Navbar from './components/Navbar.js/Navbar';
import Homepage from './pages/Home/Homepage';
import ClientLayout from './components/Layouts/Client/ClientLayout'
import UserLogin from './components/Auth/Login';
import ClientProtectedRoute from './pages/Client/ProtectedRoutes';
import PremiumProtectedRoute from './pages/Client/PremiumRoutes';
import Footer from './components/Footer/Footer'
import About from './pages/Home/About';
import Pricing from './pages/Home/Pricing';
import ContactUs from './pages/Home/Contact';

// ? Importing Simulation  Pages
import Dashboard from './pages/Client/Dashboard'
import InvoiceHashing from './pages/Client/Simulation/InvoiceHashing/InvoiceHashing'
import InvoiceQR from './pages/Client/Simulation/InvoiceQR/InvoiceQR'
import TaxPayer from './pages/Client/Simulation/TaxPayer/TaxPayer'
import Onbaord from './pages/Client/Simulation/OnBoard/OnBoard'
import Compliance from './pages/Client/Simulation/Compliance/Compliance'
import Production from './pages/Client/Simulation/Production/Production'
import Clearance from './pages/Client/Simulation/Clearance/Clearance'

// ? Importing Production Pages
import Auth from './pages/Client/Production/Auth/Authorization'
import InvoiceSigning from './pages/Client/Production/InvoiceHashing/Page'
import InvoiceQRGen from './pages/Client/Production/InvoiceQR/Page'
import TaxPayerCSR from './pages/Client/Production/TaxPayer/Page'
import OnboardDevice from './pages/Client/Production/Onboard/Page'
import ComplianceProd from './pages/Client/Production/Compliance/Page'
import ProductionProd from './pages/Client/Production/Production/Page'
import ClearanceProd from './pages/Client/Production/Clearance/Page'

function App() {
  return (
    <>
      <Navbar />

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/login" element={<UserLogin />} />

        <Route path='/dashboard' element={<ClientProtectedRoute Component={ClientLayout} />}>
          <Route index element={<Dashboard />} />
          <Route path='invoice-hashing' element={<InvoiceHashing />} />
          <Route path='invoice-qr' element={<InvoiceQR />} />
          <Route path='taxpayer' element={<TaxPayer />} />
          <Route path='onboarding' element={<Onbaord />} />
          <Route path='compliance' element={<Compliance />} />
          <Route path='production-pcsid' element={<Production />} />
          <Route path='clearance' element={<Clearance />} />

          <Route path='authorization' element={<PremiumProtectedRoute Component={Auth} />} />
          <Route path='invoice-signing' element={<PremiumProtectedRoute Component={InvoiceSigning} />} />
          <Route path='invoice-qr-generator' element={<PremiumProtectedRoute Component={InvoiceQRGen} />} />
          <Route path='gen-taxpayer-csr' element={<PremiumProtectedRoute Component={TaxPayerCSR} />} />
          <Route path='onboard-device' element={<PremiumProtectedRoute Component={OnboardDevice} />} />
          <Route path='compliance-prod' element={<PremiumProtectedRoute Component={ComplianceProd} />} />
          <Route path='production-prod' element={<PremiumProtectedRoute Component={ProductionProd} />} />
          <Route path='clearance-prod' element={<PremiumProtectedRoute Component={ClearanceProd} />} />
        </Route>

      </Routes>

      <Footer />
    </>
  );
}

export default App;
