import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../context/data';
import { useUser } from '@clerk/clerk-react';

const OnBoardingForm = () => {
    const { authURL } = useAuth();
    const [inputMethod, setInputMethod] = useState('manual');
    const [emailAddress, setEmailAddress] = useState('');
    const [C, setC] = useState('');
    const [OTP, setOTP] = useState('');
    const [OU, setOU] = useState('');
    const [O, setO] = useState('');
    const [CN, setCN] = useState('');
    const [SN, setSN] = useState('');
    const [UID, setUID] = useState('');
    const [title, setTitle] = useState('');
    const [registeredAddress, setRegisteredAddress] = useState('');
    const [businessCategory, setBusinessCategory] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [csrData, setCsrData] = useState(null);
    const [file, setFile] = useState(null);
    const { user } = useUser();
    const [invoiceHashingCount, setInvoiceHashingCount] = useState(0);
    const [isTrialEnded, setIsTrialEnded] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const fetchUserStatistics = async () => {
        try {
            const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`);
            if (!response.ok) {
                throw new Error('Failed to fetch user statistics');
            }

            const userData = await response.json();
            const invoiceHashing = userData.count.onboardDevice;
            setInvoiceHashingCount(invoiceHashing);
            setLoaded(true);
            if (invoiceHashing >= 7) {
                setIsTrialEnded(true);
            } else {
                setIsTrialEnded(false);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    const updateUserInvoiceHashingCount = async () => {
        try {
            const response = await fetch(`${authURL}/update-user-stats?email=${user.primaryEmailAddress.emailAddress}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'count.onboardDevice': invoiceHashingCount + 1  // Only update count.invoiceHashing field
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update invoice hashing count');
            }

            setInvoiceHashingCount(invoiceHashingCount + 1);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleGenerateData = async () => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        const formData = new FormData();
        if (inputMethod === 'file' && file) {
            formData.append('csrFile', file);
        } else {
            formData.append('emailAddress', emailAddress);
            formData.append('C', C);
            formData.append('OU', OU);
            formData.append('O', O);
            formData.append('CN', CN);
            formData.append('SN', SN);
            formData.append('UID', UID);
            formData.append('title', title);
            formData.append('registeredAddress', registeredAddress);
            formData.append('businessCategory', businessCategory);
        }
        formData.append('OTP', OTP);

        try {
            const response = await fetch(`${authURL}/onboard-simulation`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.message || 'Failed to process data');
                throw new Error(errorData.message || 'Failed to process data');
            }

            const data = await response.json();
            setCsrData(data.data); // Assuming `data` contains CSR data
            setSuccess('CSR data generated successfully.');
            updateUserInvoiceHashingCount();
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('An unexpected error occurred. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    useEffect(() => {
        fetchUserStatistics();
    }, [fetchUserStatistics]);

    if (isTrialEnded) {
        return <p className="text-red-500 mt-4">Your trial has ended. Please upgrade your plan to continue.</p>;
    }


    return (
        <>
            {!isLoaded && !isTrialEnded ? (
                <div className="max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
                    <l-zoomies
                        size="80"
                        stroke="5"
                        bg-opacity="0.1"
                        speed="1.4"
                        color="black"
                    ></l-zoomies>
                </div>
            ) : (
                <div className="w-full mx-auto">
                    <div className='flex items-end justify-between mb-12'>
                        <div>
                            <h1 className='text-5xl font-light mb-2'>Onboard Device Now</h1>
                            <p className='text-md text-gray-600'>Onboard your device to get started with Invoice Clearance and Production processes.</p>
                        </div>

                        <div className='flex flex-col items-end justify-end space-x-2'>
                            <h5 class="text-4xl font-bold text-blue-700 bg-blue-300 px-4 py-2 rounded-xl">{invoiceHashingCount}</h5>
                            <span class="text-md text-gray-500">Trials Remaining</span>
                        </div>

                    </div>
                    <h2 className="text-2xl font-medium my-8">Try it Now</h2>

                    {/* <div className='mb-4'>
                        <label className="block text-sm font-medium mb-1" htmlFor="inputMethod">Select Input Method</label>
                        <select
                            id="inputMethod"
                            value={inputMethod}
                            onChange={(e) => setInputMethod(e.target.value)}
                            className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                        >
                            <option value="manual">Enter Data Manually</option>
                            <option value="file">Upload CSR File</option>
                        </select>
                    </div> */}

                    {inputMethod === 'file' ? (
                        <div className='mb-4'>
                            <label className="block text-sm font-medium mb-1" htmlFor="file">Upload CSR File</label>
                            <input
                                id="file"
                                type="file"
                                accept=".csr"
                                onChange={(e) => setFile(e.target.files[0])}
                                className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                            />
                        </div>
                    ) : (
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="emailAddress">Email Address</label>
                                <input
                                    id="emailAddress"
                                    type="text"
                                    value={emailAddress}
                                    onChange={(e) => setEmailAddress(e.target.value)}
                                    placeholder="Email Address"
                                    className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="C">C</label>
                                <input
                                    id="C"
                                    type="text"
                                    value={C}
                                    onChange={(e) => setC(e.target.value)}
                                    placeholder="SA, CN, PK, DB"
                                    className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="OU">OU</label>
                                <input
                                    id="OU"
                                    type="text"
                                    value={OU}
                                    onChange={(e) => setOU(e.target.value)}
                                    placeholder="1234567890"
                                    className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="O">O</label>
                                <input
                                    id="O"
                                    type="text"
                                    value={O}
                                    onChange={(e) => setO(e.target.value)}
                                    placeholder="Organization Website Address"
                                    className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="CN">CN</label>
                                <input
                                    id="CN"
                                    type="text"
                                    value={CN}
                                    onChange={(e) => setCN(e.target.value)}
                                    placeholder="<CODE>-Organization Website Address"
                                    className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="SN">SN</label>
                                <input
                                    id="SN"
                                    type="text"
                                    value={SN}
                                    onChange={(e) => setSN(e.target.value)}
                                    placeholder="1-Organization Website Address|2-TV1|3-ed22f1d8-e6a2-1118-9b58-d9a8f11e463"
                                    className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="UID">UID</label>
                                <input
                                    id="UID"
                                    type="text"
                                    value={UID}
                                    onChange={(e) => setUID(e.target.value)}
                                    placeholder="302008011700003"
                                    className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="title">Title</label>
                                <select
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                                >
                                    <option value="">Select Title</option>
                                    <option value="1000">B2B</option>
                                    <option value="0100">B2C</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="registeredAddress">Registered Address</label>
                                <input
                                    id="registeredAddress"
                                    type="text"
                                    value={registeredAddress}
                                    onChange={(e) => setRegisteredAddress(e.target.value)}
                                    placeholder="Registered Address"
                                    className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1" htmlFor="businessCategory">Business Category</label>
                                <input
                                    id="businessCategory"
                                    type="text"
                                    value={businessCategory}
                                    onChange={(e) => setBusinessCategory(e.target.value)}
                                    placeholder="Business/Organization Name"
                                    className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                                />
                            </div>
                        </div>
                    )}

                    <div className='mb-4'>
                        <label className="block text-sm font-medium mb-1" htmlFor="otp">One-Time Password - OTP</label>
                        <input
                            type="text"
                            value={OTP}
                            onChange={(e) => setOTP(e.target.value)}
                            placeholder="OTP from ZATCA Portal"
                            className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                        />
                    </div>

                    <button
                        className="bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-lg transition duration-200 px-4 py-2 rounded disabled:opacity-50"
                        onClick={handleGenerateData}
                        disabled={loading}
                    >
                        {loading ? 'Onboarding...' : 'Onboard Now'}
                    </button>

                    {error && (
                        <div className="text-red-500 mt-4">
                            <strong>Error:</strong> {error}
                        </div>
                    )}
                    {success && (
                        <div className="text-green-500 mt-4">
                            <strong>Success:</strong> {success}
                        </div>
                    )}

                    {csrData && (
                        <div className="flex justify-center mt-12">
                            <div className='w-full'>
                                <code className="text-lg font-medium">CSR Data:</code>
                                <div className="flex items-center mt-2 space-x-4">
                                    <pre className="bg-gray-100 p-4 rounded-lg max-w-4xl overflow-x-auto">
                                        <code className="text-sm">{csrData}</code>
                                    </pre>
                                    <button
                                        onClick={() => copyToClipboard(csrData)}
                                        className="bg-gray-100 p-2 rounded"
                                    >
                                        <i className="fa-regular fa-copy"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>

    );
};

export default OnBoardingForm;
