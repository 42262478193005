import React from 'react';
import InvoiceHashComponent from './Form';

const InvoiceHashing = () => {

    return (
        <>
            <InvoiceHashComponent></InvoiceHashComponent>
        </>
    );
};

export default InvoiceHashing;