import React from 'react';
import InvoiceQRForm from './Form';

const InvoiceQR = () => {
    return (
        <>
            <InvoiceQRForm></InvoiceQRForm>
        </>
    );
};

export default InvoiceQR;