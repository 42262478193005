import React from 'react';
import hero from '../../assets/hero.webp'
import zatca from '../../assets/zatca.png'

const HeroSection = () => {
    return (
        <section className="relative">
            <section class="pt-32 pb-20 bg-white overflow-hidden">
                <div class="max-w-7xl mx-auto h-full py-4">

                    <div class="flex flex-wrap lg:items-center pb-2 -m-8">
                        <div class="w-full md:w-1/2 p-8">
                            <div class="max-w-max mx-auto md:mr-0 rounded-3xl">
                                <img class="mx-auto" src={hero} alt="" />
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 p-8">
                            <div class="md:max-w-lg">
                                <span class="inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest secFont">Developed by BZ Softech</span>
                                <h1 class="mb-4 text-5xl text-gray-900 font-light tracking-tight leading-snug">Integrate Your Existing ERPs, POS & Applications With ZATCA APIs</h1>
                                <p class="mb-4 font-bold ">Integrate your existing ERPs, POS, and applications with ZATCA seamlessly. Try the ZATCA API as a trial version instantly!</p>
                                <div class="flex flex-wrap mb-11 mt-4">
                                    <div class="w-full md:w-auto"><a className='mr-4 text-gray-900 bg-white border px-4 py-2 secFont text-sm font-semibold rounded'>Get free Consultation</a></div>
                                    <div class="w-full md:w-auto"><a className='mr-4 text-white bg-gray-900 px-4 py-2 secFont text-sm font-semibold rounded' >Try APIs now</a></div>
                                </div>
                                <div class="flex flex-wrap w-full">
                                    <div class="flex items-start justify-center space-x-4 w-full h-full">
                                        <img src={zatca} alt="" className='w-20' />
                                        <div>
                                            <h1 class="mb-1 font-semibold text-gray-900 tracking-tight">A ZATCA Approved Solution Provider</h1>
                                            <p class="text-sm ">100% ZATCA Compliance: Saudi Based Solution For Saudi Businesses</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default HeroSection;
