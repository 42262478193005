import React from 'react'

function Services() {
    return (
        <div className='max-w-7xl mx-auto py-24'>
            <h2 class="secFont mb-12 text-6xl tracking-tighter">Why choose Us?</h2>

            <div class="grid grid-cols-2 gap-4 sm:grid-cols-3">

                <div class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
                    <span class="inline-block rounded-lg  p-3">
                        <i class="fa-duotone fa-solid fa-shield-check fa-2xl"></i>
                    </span>
                    <h2 class="mt-2 font-semibold text-lg">Fully compliant</h2>
                    <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                        An approved ZATCA solution provider, ensuring 100% audit-proof invoicing.
                    </p>
                </div>

                <div class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
                    <span class="inline-block rounded-lg  p-3">
                        <i class="fa-duotone fa-solid fa-file-certificate fa-2xl"></i>
                    </span>
                    <h2 class="mt-2 font-semibold text-lg">Audited & Certified</h2>
                    <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                        Our customers setups right from ZATCA wave 1 are 100% audited and certified.
                    </p>
                </div>

                <div class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
                    <span class="inline-block rounded-lg  p-3">
                        <i class="fa-duotone fa-solid fa-cloud-arrow-up fa-2xl"></i>
                    </span>
                    <h2 class="mt-2 font-semibold text-lg">Flexibility in Deployment</h2>
                    <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                        Choose our SAAS Cloud or your own server for deployment.
                    </p>
                </div>


                <div class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
                    <span class="inline-block rounded-lg  p-3">
                        <i class="fa-duotone fa-solid fa-calendar-days fa-2xl"></i>
                    </span>
                    <h2 class="mt-2 font-semibold text-lg">Rapid Implementation</h2>
                    <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                        Go live in just 1 day with guaranteed ZATCA integration.
                    </p>
                </div>


                <div class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
                    <span class="inline-block rounded-lg  p-3">
                        <i class="fa-duotone fa-solid fa-gear fa-2xl"></i>
                    </span>
                    <h2 class="mt-2 font-semibold text-lg">Packaged Solution</h2>
                    <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                        Comprehensive solutions tailored for SAP, Oracle, MSD, Infor, BAAN, SAGE, Custom & Inhouse ERP/POS
                    </p>
                </div>

                <div class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
                    <span class="inline-block rounded-lg  p-3">
                        <i class="fa-duotone fa-solid fa-gauge fa-2xl"></i>
                    </span>
                    <h2 class="mt-2 font-semibold text-lg">High Performance</h2>
                    <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                        Capable of processing millions of invoices daily.
                    </p>
                </div>

            </div>

        </div >
    )
}

export default Services
