import React from 'react';
import OnBoardingForm from './Form';

const OnBoard = () => {
    return (
        <>
            <OnBoardingForm></OnBoardingForm>
        </>
    );
};

export default OnBoard;