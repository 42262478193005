import React from 'react';
import ProductionForm from './Form';

const Production = () => {
    return (
        <>


            <ProductionForm></ProductionForm>
        </>
    );
};

export default Production;