import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../context/data'; // Assuming you have similar context
import { useUser } from '@clerk/clerk-react';

const TaxPayerForm = () => {
    const { user } = useUser();
    const { authURL } = useAuth();
    const [emailAddress, setEmailAddress] = useState('');
    const [C, setC] = useState('');
    const [OU, setOU] = useState('');
    const [O, setO] = useState('');
    const [CN, setCN] = useState('');
    const [SN, setSN] = useState('');
    const [UID, setUID] = useState('');
    const [title, setTitle] = useState('');
    const [registeredAddress, setRegisteredAddress] = useState('');
    const [businessCategory, setBusinessCategory] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [csrData, setCsrData] = useState(null);
    const [invoiceHashingCount, setInvoiceHashingCount] = useState(0);
    const [isTrialEnded, setIsTrialEnded] = useState(false);
    const [isLoaded, setLoaded] = useState(false);


    const fetchUserStatistics = async () => {
        try {
            const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`);
            if (!response.ok) {
                throw new Error('Failed to fetch user statistics');
            }

            const userData = await response.json();
            const invoiceHashing = userData.count.taxpayer;
            setInvoiceHashingCount(invoiceHashing);
            setLoaded(true);
            if (invoiceHashing >= 7) {
                setIsTrialEnded(true);
            } else {
                setIsTrialEnded(false);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    const updateUserInvoiceHashingCount = async () => {
        try {
            const response = await fetch(`${authURL}/update-user-stats?email=${user.primaryEmailAddress.emailAddress}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'count.taxpayer': invoiceHashingCount + 1  // Only update count.invoiceHashing field
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update invoice hashing count');
            }

            setInvoiceHashingCount(invoiceHashingCount + 1);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleGenerateData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${authURL}/taxpayer-simulation`, { // Updated API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    emailAddress,
                    C,
                    OU,
                    O,
                    CN,
                    SN,
                    UID,
                    title,
                    registeredAddress,
                    businessCategory
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to process data');
            }

            const data = await response.json();
            setCsrData(data.csr); // Store the CSR data
            updateUserInvoiceHashingCount();
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    useEffect(() => {
        fetchUserStatistics();
    }, [fetchUserStatistics]);

    if (isTrialEnded) {
        return <p className="text-red-500 mt-4">Your trial has ended. Please upgrade your plan to continue.</p>;
    }

    return (
        <>
            {!isLoaded && !isTrialEnded ? (
                <div className="max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
                    <l-zoomies
                        size="80"
                        stroke="5"
                        bg-opacity="0.1"
                        speed="1.4"
                        color="black"
                    ></l-zoomies>
                </div>
            ) : (
                <div className="w-full mx-auto">
                    <div className='flex items-end justify-between mb-12'>
                        <div>
                            <h1 className='text-5xl font-light mb-2'>Generate Tax Payer CSR</h1>
                            <p className='text-md text-gray-600'>Generate a <code className='text-blue-700'>PrivateKey.pem</code> ,<code className='text-blue-700'> config.cnf</code> and <code className='text-blue-700'>taxpayer.csr</code> file</p>
                        </div>

                        <div className='flex flex-col items-end justify-end space-x-2'>
                            <h5 class="text-4xl font-bold text-blue-700 bg-blue-300 px-4 py-2 rounded-xl">{invoiceHashingCount}</h5>
                            <span class="text-md text-gray-500">Trials Remaining</span>
                        </div>

                    </div>
                    <h2 className="text-2xl font-medium my-8">Try it Now</h2>

                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-sm font-medium mb-1" htmlFor="emailAddress">Email Address</label>
                            <input
                                id="emailAddress"
                                type="text"
                                value={emailAddress}
                                onChange={(e) => setEmailAddress(e.target.value)}
                                placeholder="Email Address"
                                className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1" htmlFor="C">C</label>
                            <input
                                id="C"
                                type="text"
                                value={C}
                                onChange={(e) => setC(e.target.value)}
                                placeholder="SA, CN, PK, DB"
                                className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1" htmlFor="OU">OU</label>
                            <input
                                id="OU"
                                type="text"
                                value={OU}
                                onChange={(e) => setOU(e.target.value)}
                                placeholder="1234567890"
                                className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1" htmlFor="O">O</label>
                            <input
                                id="O"
                                type="text"
                                value={O}
                                onChange={(e) => setO(e.target.value)}
                                placeholder="Organization Website Address"
                                className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1" htmlFor="CN">CN</label>
                            <input
                                id="CN"
                                type="text"
                                value={CN}
                                onChange={(e) => setCN(e.target.value)}
                                placeholder="<CODE>-Organization Website Address"
                                className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1" htmlFor="SN">SN</label>
                            <input
                                id="SN"
                                type="text"
                                value={SN}
                                onChange={(e) => setSN(e.target.value)}
                                placeholder="1-Organization Website Address|2-TV1|3-ed22f1d8-e6a2-1118-9b58-d9a8f11e463"
                                className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1" htmlFor="UID">UID</label>
                            <input
                                id="UID"
                                type="text"
                                value={UID}
                                onChange={(e) => setUID(e.target.value)}
                                placeholder="302008011700003"
                                className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1" htmlFor="title">Title</label>
                            <select
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                            >
                                <option value="">Select Title</option>
                                <option value="1000">B2B</option>
                                <option value="0100">B2C</option>
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1" htmlFor="registeredAddress">Registered Address</label>
                            <input
                                id="registeredAddress"
                                type="text"
                                value={registeredAddress}
                                onChange={(e) => setRegisteredAddress(e.target.value)}
                                placeholder="Registered Address"
                                className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1" htmlFor="businessCategory">Business Category</label>
                            <input
                                id="businessCategory"
                                type="text"
                                value={businessCategory}
                                onChange={(e) => setBusinessCategory(e.target.value)}
                                placeholder="Business/Organization Name"
                                className="p-3 bg-gray-100 border border-gray-300 rounded outline-none w-full"
                            />
                        </div>
                    </div>

                    <button
                        className="bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-lg transition duration-200 px-4 py-2 rounded disabled:opacity-50"
                        onClick={handleGenerateData}
                        disabled={loading}
                    >
                        {loading ? 'Generating...' : 'Generate Tax Payer CSR'}
                    </button>

                    {error && <p className="text-red-500 mt-4">{error}</p>}

                    {csrData && (
                        <div className="flex justify-center mt-12">
                            <div className='w-full'>
                                <code className="text-lg font-medium">CSR Data:</code>
                                <div className="flex items-center mt-2 space-x-4">
                                    <pre className="bg-gray-100 p-4 rounded-lg max-w-4xl overflow-x-auto">
                                        <code className="text-sm">{csrData}</code>
                                    </pre>
                                    <button
                                        onClick={() => copyToClipboard(csrData)}
                                        className="bg-gray-100 p-2 rounded"
                                    >
                                        <i className="fa-regular fa-copy"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default TaxPayerForm;
